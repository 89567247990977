
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.legal {
    font-family: "Poppins", sans-serif;
    padding-bottom: 2rem;
    @apply px-4 lg:px-7;
    h1 {
        margin-bottom: 1.8rem;
        font-size: 1.5rem;
        line-height: 1.4;
        font-weight: 700;
        @media screen and (min-width: 900px) {
            margin-bottom: 1.8rem;
            font-size: 2.125rem;
            line-height: 1.4;
            font-weight: 700;
        }
    }

    section {
        padding: 1.5rem 0;
        border-bottom: 1px solid;
        border-color: $perfect-auto-warranty-shining-silver;
        p {
            margin-bottom: 1.5rem;
            &:last-child {
                margin-bottom: 0;
            }
            @apply text-sm lg:text-base font-light;
        }
        h2 {
            @apply font-bold mb-4 text-lg lg:text-xl;
        }
        ul {
            padding-left: 10px;
            margin-bottom: 2rem;
            li {
                margin-bottom: 1rem;
                padding-left: 10px;
                @apply font-light text-sm lg:text-base list-inside list-disc;
                &::last-child {
                    margin-bottom: 0;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        a {
            text-decoration: underline;
            color: blue;
        }
        div {
            @apply mb-4 lg:mb-7 lg:pl-2;
            h3 {
                @apply font-medium text-base lg:text-lg mb-3;
            }
        }
        &:last-child {
            border: none;
        }
    }
}
.ccpaMainContainer {
    margin-top: 25px;
    margin-bottom: 60px;
    h1 {
        font-weight: bold;
        font-size: 20px;

        @include lg {
            font-size: 28px;
            margin-top: 50px;
        }
    }
    p {
        font-size: 16px;
        line-height: 30px;
        margin-top: 10px;
        font-weight: 300;
        @include lg {
            font-size: 18px;
            margin-top: 20px;
        }
    }
    h2 {
        font-size: 18;
        margin-top: 10px;
        @include lg {
            font-size: 24px;
            margin-top: 20px;
        }

        font-weight: bold;
    }
    h3 {
        font-size: 16;
        margin-top: 5px;
        @include lg {
            font-size: 20px;
            margin-top: 10px;
        }

        font-weight: bold;
    }
    a {
        color: #1693f0;
        &:hover {
            text-decoration: underline;
        }
    }
    .decimalStyleOnlyLi {
        li {
            font-size: 16px;
            line-height: 30px;
            margin-top: 10px;
            font-weight: 300;
            @include lg {
                font-size: 18px;
                margin-top: 20px;
            }
        }
    }
    .decimalStyle,
    .decimalStyleOnlyLi {
        list-style-type: none;
        counter-reset: css-counter 0; /* initializes counter to 0; use -1 for zero-based numbering */
    }

    .decimalStyle li h2,
    .decimalStyleOnlyLi li {
        counter-increment: css-counter 1; /* Increase the counter by 1. */
    }

    .decimalStyle li h2:before,
    .decimalStyleOnlyLi li:before {
        content: counter(css-counter) ". "; /* Apply counter before children's content. */
    }
    .alphaStyle {
        list-style: lower-alpha;
        margin-left: 30px;
        @include lg {
            margin-left: 40px;
        }

        li {
            font-size: 16;

            @include lg {
                font-size: 20px;
            }

            font-weight: bold;
        }
    }
    address {
        margin-top: 20px;
        font-size: 18px;
    }
    ul {
        list-style: disc;
        margin-left: 30px;
        li {
            font-size: 16px;
            line-height: 30px;
            margin-top: 10px;
            font-weight: 300;
            @include lg {
                font-size: 18px;
                margin-top: 20px;
            }
        }
    }
    .timeStyle {
        font-size: 13px;
        color: #999;
    }
}
